<div class="row mb-5">
    <div class="col-sm  d-flex justify-content-center">
        <a [routerLink]="['/playlists']" routerLinkActive="active-link">
            <h3>playlists</h3>
        </a>  
    </div>
    <div class="col-sm d-flex justify-content-center">
        <a [routerLink]="['/musicians']" routerLinkActive="active-link">
            <h3>musicians</h3>
        </a>  
    </div>
    <div class="col-sm d-flex justify-content-center">
        <a [routerLink]="['/calendar']" routerLinkActive="active-link">
            <h3>calendar</h3>
        </a>  
    </div>
</div>