<h4 class="my-4">In no particular order...</h4>
<div *ngFor="let playlist of playlists" class="mb-5">
    <div class="row">
        <b class="col-12">{{ playlist.name }}</b>
        <span class="my-1 ml-3 smallerFont"> curator: {{ playlist.creator }}</span>
    </div>
    <div class="row mt-2">
        <div class="col-12">
            {{ playlist.description }}
        </div>
    </div>
    <div class="row mt-2">
        <a *ngFor="let link of toArray(playlist.links)" class="btn btn-primary col-3 mx-3  border-0" target="_blank"
            href='{{ link.url }}' role="button" [ngStyle]="{'background-color': link.platform === 'Spotify' ? '#1DB954' : 'black'}">
            {{ link.platform }}
        </a>
    </div>
</div>